import styled from 'styled-components';
import { Input } from 'antd';

const StyledInput = styled(Input)`
  &.label.ant-input[disabled] {
    border: none;
    background: none !important;
    color: var(--text-primary);
    font-weight: 400;
    cursor: auto;
  }
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '3px')};
  color: ${props => (props.color ? props.color : 'var(--text-primary)')};
  background: ${props =>
    props.background
      ? props.background
      : props.disabled
      ? '#f5f5f5'
      : '#FFFFFF'} !important;
  ${props => (props.disabled ? 'font-weight: 700;' : '')};
  border-color: ${props => (props.bordercolor ? props.bordercolor : '#d9d9d9')};
  margin: ${props => (props.margin ? props.margin : '0')};
  width: ${props => (props.width ? props.width : '-webkit-fill-available')};

  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background: ${props => (props.background ? props.background : '#FFFFFF')};
  }

  &::placeholder {
    font-size: 1em;
    color: ${props =>
      props.placeholdercolor ? props.placeholdercolor : '#9D9D9D'};
  }

  &:hover {
    border-color: ${props =>
      props.bordercolor ? props.bordercolor : '#c3c9d3'};
  }

  &:focus {
    border-color: ${props =>
      props.bordercolor ? props.bordercolor : '#c3c9d3'};
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const StyledDefaultInput = styled.input`
  width: ${props => (props.loading ? props.loading : '100%')};
  height: 30px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  margin-right: 10px;

  &:focus {
    border-color: #c3c9d3;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
  }
`;

export { StyledInput, StyledDefaultInput };
