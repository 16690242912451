import ApiService from 'utils/ApiService';
import React, { createContext, useState } from 'react';
import { popToast } from 'utils/useToast';
import { random_colors } from 'utils/helpers';
import { withRouter } from 'react-router-dom';

const AuthContext = createContext();
const Provider = AuthContext.Provider;
const AuthConsumer = AuthContext.Consumer;

const { post, put } = ApiService();

const Auth = props => {
  const auth = localStorage.is_authenticated
    ? JSON.parse(localStorage.is_authenticated)
    : false;
  const [is_authenticated, setAuthValue] = useState(auth);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const value = {
    signIn: signIn,
    signOut: signOut,
    verify: verify,
    forgot: forgot,
    reset: reset,
    isLogoutLoading: isLogoutLoading,
    setAuthValue: setAuthValue,
    is_authenticated: is_authenticated,
  };

  function signIn(body) {
    // replace this with the app's proper authentication
    return post('/auth/login', body)
      .then(res => {
        const {
          data: { locations = [] },
        } = res;
        if (res.data.temp_pass) {
          sessionStorage.setItem('session', res.data.session);
          return res;
        } else {
          localStorage.setItem('error_counter', 0);
          localStorage.setItem(
            'token',
            JSON.stringify(res.data.authentication_result),
          );
          localStorage.setItem(
            'role_permissions',
            JSON.stringify(res.data.role_permissions),
          );
          localStorage.setItem(
            'avatar_color',
            random_colors[Math.floor(Math.random() * (71 - 0 + 1)) + 0],
          );
          localStorage.setItem('user', JSON.stringify(res.data.user));
          localStorage.setItem('user_locations', JSON.stringify(locations));
          setAuthValue(true);
          localStorage.setItem('is_authenticated', true);
        }
      })
      .catch(err => {
        throw err.data;
      });
  }
  function forgot(body) {
    return post('/auth/forgot-password', body)
      .then(res => res)
      .catch(err => {
        throw err.data;
      });
  }

  function reset(body) {
    return post('/auth/confirm-forgot-password', body)
      .then(res => res)
      .catch(err => {
        throw err.data;
      });
  }

  function verify(body) {
    return put('/auth/verify', body)
      .then(res => {
        const {
          data: { locations = [] },
        } = res;
        if (res.success) {
          localStorage.setItem('error_counter', 0);
          localStorage.setItem('is_authenticated', true);
          localStorage.setItem(
            'token',
            JSON.stringify(res.data.authentication_result),
          );
          localStorage.setItem(
            'role_permissions',
            JSON.stringify(res.data.role_permissions),
          );

          localStorage.setItem(
            'avatar_color',
            random_colors[Math.floor(Math.random() * (71 - 0 + 1)) + 0],
          );
          localStorage.setItem('user', JSON.stringify(res.data.user));
          localStorage.setItem('user_locations', JSON.stringify(locations));
          setAuthValue(true);
          props.history.push('/');
        }
      })
      .catch(err => {
        throw err.data;
      });
  }

  function signOut() {
    setIsLogoutLoading(true);
    post('/auth/logout')
      .then(res => {
        if (res.success) {
          popToast({
            message: res.message,
            type: 'success',
          });
          localStorage.clear();
          sessionStorage.clear();
          setAuthValue(false);
          props.history.push('/auth');
          setIsLogoutLoading(false);
        }
      })
      .catch(err => {
        setIsLogoutLoading(false);
        popToast({
          message: err.message,
          type: 'error',
        });
        localStorage.clear();
        sessionStorage.clear();
        setAuthValue(false);
        props.history.push('/auth');
      });
  }

  return <Provider value={value}>{props.children}</Provider>;
};

const AuthProvider = withRouter(Auth);
export { AuthContext, AuthProvider, AuthConsumer };
