import React, { Fragment } from 'react';
import axios from 'axios';
import urls from 'utils/config';
import { Form, Modal } from 'antd';
import { StyledInput } from 'components/styledComponents/input';
import { popToast } from 'utils/useToast';

var passwordPolicy = {};
var formData = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};
const formItemLayout = {
  id: 'change_pass',
  labelAlign: 'left',
  colon: false,
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const env = process.env.REACT_APP_ENV;
const default_api = urls[env].route;
const validateMessages = {
  required: 'Please fill out this field.',
};

function handleChange(e) {
  formData[e.target.name] = e.target.value;
}
function retrivePasswordPolicy() {
  let url = `${default_api}:8000/api`;
  let getToken = JSON.parse(localStorage.getItem('token'));
  let session = sessionStorage.session;
  let access_token = getToken ? getToken.AccessToken : '';
  let id_token = getToken ? getToken.IdToken : '';
  let refresh_token = getToken ? getToken.RefreshToken : '';
  const headers = {
    session,
    access_token,
    id_token,
    refresh_token,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const type = 'Admin User Pool';
  return fetch(`${url}/password-policy?${type}`, {
    method: 'GET',
    headers,
  }).then(async res => {
    return await res.json();
  });
}
const modalContents = () => {
  return (
    <Fragment>
      <Form
        {...formItemLayout}
        id="change_password"
        className="ant-advanced-search-form"
        validateMessages={validateMessages}
      >
        <Form.Item
          label="OLD PASSWORD"
          name="old_password"
          rules={[
            {
              required: true,
            },
            {
              min: 8,
              message: 'Old Password must be at least 8 characters.',
            },
          ]}
        >
          <StyledInput.Password
            type="password"
            name="old_password"
            value={formData['old_password']}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label="NEW PASSWORD"
          name="new_password"
          id="new_pasword"
          rules={[
            {
              required: true,
              message: 'Please input a new password.',
            },
            {
              min: 8,
              message: 'Password must be at least 8 characters.',
            },
            {
              validator: async (rule, value) => {
                var elements = document.getElementById('change_password')
                  .childNodes;
                var item = elements[elements.length - 1];
                var hasErrorEl = item.querySelector('.ant-form-item-explain');
                if (value === formData.confirm_password) {
                  item.classList.remove('ant-form-item-has-error');
                  item.classList.add('ant-form-item-has-success');
                  if (hasErrorEl) {
                    hasErrorEl.style.visibility = 'hidden';
                  }
                } else {
                  item.classList.add('ant-form-item-has-error');
                  if (hasErrorEl) {
                    hasErrorEl.style.visibility = 'visible';
                  }
                }
              },
            },
          ]}
        >
          <StyledInput.Password
            type="password"
            name="new_password"
            value={formData['new_password']}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label="CONFIRM PASSWORD"
          name="confirm_password"
          rules={[
            {
              required: true,
              message: 'Please input a new password.',
            },
            {
              validator: async (rule, value) => {
                var elements = document.getElementById('change_password')
                  .childNodes;
                var item = elements[elements.length - 1];
                var hasErrorEl = item.querySelector('.ant-form-item-explain');
                if (hasErrorEl) {
                  hasErrorEl.style.visibility = 'visible';
                }

                if (formData.new_password !== value) {
                  throw new Error('Password did not match!');
                }
              },
            },
          ]}
        >
          <StyledInput.Password
            type="password"
            name="confirm_password"
            value={formData['confirm_password']}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
      <div className="password-policy">
        <div className="title">Password Policy</div>
        <div className="content">
          {passwordPolicy?.min_length && (
            <div>
              · The minimum password length is {passwordPolicy?.min_length || 0}
            </div>
          )}
          {passwordPolicy?.require_uppercase && (
            <div>· At least one uppercase (A-Z)</div>
          )}
          {passwordPolicy?.require_lowercase && (
            <div>· At least one lowercase (a-z)</div>
          )}
          {passwordPolicy?.require_numbers && <div>· At least one number</div>}
          {passwordPolicy?.require_symbols && <div>· At least one symbol</div>}
          {passwordPolicy?.prevent_reuse_count && (
            <div>
              · Password is not the same as the last four (
              {passwordPolicy?.prevent_reuse_count}) password
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
function handleSubmit(close) {
  const hasError = [];
  var elements = document.getElementById('change_password').childNodes;
  elements.forEach(function(el) {
    var spec = el.querySelector('.ant-form-item-explain');
    spec && hasError.push(spec);
  });
  if (
    formData?.old_password.length >= 8 &&
    formData?.new_password.length >= 8 &&
    formData?.confirm_password.length >= 8 &&
    formData?.new_password.length === formData?.confirm_password.length
  ) {
    if (hasError.length <= 1) {
      let getToken = JSON.parse(localStorage.getItem('token'));
      let session = sessionStorage.session;
      let access_token = getToken ? getToken.AccessToken : '';
      let id_token = getToken ? getToken.IdToken : '';
      let refresh_token = getToken ? getToken.RefreshToken : '';
      let url = `${default_api}:8000/api`;
      const headers = {
        session,
        access_token,
        id_token,
        refresh_token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const body = {
        old_password: formData.old_password,
        new_password: formData.new_password,
        confirm_password: formData.confirm_password,
      };
      fetch(`${url}/users/change-password`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(body),
      }).then(async res => {
        const response = await res.json();
        if (response.success) {
          popToast({
            message: response.message,
            type: 'success',
          });
          close();
        } else {
          popToast({
            message: response?.error
              ? response?.error?.message
              : 'Something went wrong in changing password.',
            type: 'error',
          });
        }
      });
    } else {
      popToast({
        message: 'Please fill up required fields!',
        type: 'error',
      });
    }
  } else {
    popToast({
      message: 'Please fill up required fields!',
      type: 'error',
    });
  }
}
const handleSuccess = response => {
  let getToken = JSON.parse(localStorage.getItem('token'));
  const { access_token, id_token } = response.headers;
  if (access_token && id_token) {
    const new_token = {
      ...getToken,
      AccessToken: access_token,
      IdToken: id_token,
    };
    localStorage.removeItem('token');
    localStorage.setItem('token', JSON.stringify(new_token));
  }
  return response.data;
};
const handleError = async error => {
  // Convert arraybuffered error response to readable format
  // console.log(error);
  // debugger;
  let counter = localStorage.getItem('error_counter');
  if (
    error.request.responseType === 'arraybuffer' &&
    error.response.data.toString() === '[object ArrayBuffer]'
  ) {
    const err = JSON.parse(Buffer.from(error.response.data).toString('utf8'));
    if (err.error && err.error.auto_logout) {
      localStorage.setItem('error_counter', 1);
      if (counter < 1) {
        Modal.warning({
          title: `Warning! ${err.error.message}`,
          content: 'You will be forced to logout.',
          onOk: () => {
            localStorage.clear();
            Modal.destroyAll();
            sessionStorage.clear();
            window.location = '/auth';
          },
        });
      }
      throw err;
    } else {
      if (error.request.status === 500) {
        const errors = {
          data: {
            code: '500',
            message: 'Something went wrong.',
            statusText: 'Something went wrong.',
            context: 'Something went wrong.',
          },
        };
        return Promise.reject(errors);
      }
      return Promise.reject(err.response ? err.response : err);
    }
  }

  if (!error.response) {
    const errors = {
      data: {
        code: 'ZERO_RES',
        message: 'Unable to connect to server.',
        statusText: 'Unable to connect to server.',
        context: 'Please check your internet connection.',
      },
    };
    throw errors;
  }
  if (error.response) {
    const { data } = error.response;
    let errors = [
      {
        data,
      },
    ];
    if (error?.response?.data?.error?.change_password) {
      localStorage.setItem('error_counter', 1);
      if (counter < 1) {
        const res = await retrivePasswordPolicy();
        passwordPolicy = { ...res?.data[0] };
        localStorage.setItem('showCPModal', true);
        Modal.confirm({
          title: `${data?.error?.message}`,
          content: modalContents(),
          keyboard: false,
          onOk: close => handleSubmit(close),
          okText: 'Submit',
          cancelButtonProps: { style: { display: 'none' } },
          width: 600,
        });
        throw errors;
      }
    }
    if (errors[0]) {
      if (data.error && data.error.auto_logout) {
        localStorage.setItem('error_counter', 1);
        if (counter < 1) {
          Modal.warning({
            title: `Warning! ${data.error.message}`,
            content: 'You will be forced to logout.',
            onOk: () => {
              localStorage.clear();
              Modal.destroyAll();
              sessionStorage.clear();
              window.location = '/auth';
            },
          });
        }
        throw errors;
      } else {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return Promise.reject(error.response);
      }
    }
    return Promise.reject(error.response);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    return error.message;
  }
};

const handleHeaders = () => {
  // Access key for Public Modules
  let p_auth = JSON.parse(localStorage.getItem('p_auth'));
  let p_login = p_auth && p_auth.isLogin;
  let p_access = p_login
    ? {
        access_key: p_auth.customer_access_key || '',
        fk_customer_auth_id: p_auth.customer_auth.id || '',
      }
    : '';

  // Access key for Private Modules
  let getToken = JSON.parse(localStorage.getItem('token'));
  let session = sessionStorage.session;
  let access_token = getToken ? getToken.AccessToken : '';
  let id_token = getToken ? getToken.IdToken : '';
  let refresh_token = getToken ? getToken.RefreshToken : '';

  let api = localStorage.getItem('api')
    ? localStorage.getItem('api')
    : default_api;
  let port = localStorage.getItem('port')
    ? localStorage.getItem('port')
    : '8000';

  let url = `${api}:${port}/api`;
  let service = axios.create({
    baseURL: url,
    'Content-Type': 'application/json',
    headers: {
      ...p_access,
      session,
      access_token,
      id_token,
      refresh_token,
    },
  });

  service.interceptors.response.use(handleSuccess, handleError);
  return service;
};

const ApiService = () => {
  const get = (path, params) => {
    let service = handleHeaders();
    return service.get(path, params);
  };
  const post = (path, body) => {
    let service = handleHeaders();
    return service.post(path, body);
  };
  const put = (path, body) => {
    let service = handleHeaders();
    return service.put(path, body);
  };
  const remove = path => {
    let service = handleHeaders();
    service.delete(path);
  };

  return {
    get,
    post,
    put,
    remove,
  };
};

export default ApiService;
