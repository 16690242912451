import React from 'react';
import moment from 'moment';
import {
  AimOutlined,
  AuditOutlined,
  BookOutlined,
  CalendarOutlined,
  CarOutlined,
  ContactsOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  SnippetsOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';

import qs from '../../node_modules/query-string/index.js';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import clipboard from 'clipboard-polyfill/build/clipboard-polyfill.promise';
import * as API from 'utils/ApiService';

const typeOf = require('kind-of');

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const form_layout = {
  id: 'change_pass',
  labelAlign: 'left',
  colon: false,
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
  },
};
const random_colors = [
  '#7A378B',
  '#5C246E',
  '#AA00FF',
  '#BDA0CB',
  '#816687',
  '#4F2F4F',
  '#9F5F9F',
  '#CC99CC',
  '#8E388E',
  '#660198',
  '#A52A2A',
  '#CD5555',
  '#8B3A3A',
  '#6F4242',
  '#8B6969',
  '#CC3232',
  '#F08080',
  '#FF6A6A',
  '#D44942',
  '#CDB7B5',
  '#FA8072',
  '#FF5721',
  '#FF8C69',
  '#E9967A',
  '#EE7942',
  '#FF8247',
  '#F87531',
  '#FBA16C',
  '#FF7722',
  '#FF9955',
  '#EE7621',
  '#FF9912',
  '#FFC469',
  '#FCB514',
  '#DAA520',
  '#FFC125',
  '#EDCA67',
  '#E5BC3B',
  '#FDDF5B',
  '#E3CF57',
  '#D6C537',
  '#A2BC13',
  '#B3C95A',
  '#859C27',
  '#668014',
  '#54632C',
  '#8BA446',
  '#A2C93A',
  '#A3D144',
  '#A6C26E',
  '#556B2F',
  '#385E0F',
  '#88ACE0',
  '#2B4F81',
  '#4D71A3',
  '#1D7CF2',
  '#3B6AA0',
  '#75A1D0',
  '#007FFF',
  '#22316C',
  '#838EDE',
  '#AAAAFF',
  '#F6A4D5',
  '#D4318C',
  '#8B0A50',
  '#EE1289',
  '#8B3A62',
  '#FF6EB4',
  '#A2627A',
  '#FF82AB',
  '#EE799F',
  '#FFB5C5',
];

function ellipse(value) {
  return (
    <>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </div>
    </>
  );
}

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

function getRoleSelectValue(roles = [], id) {
  const arrFilteredRole = roles.filter(role => role.id === id);
  const filteredRole = arrFilteredRole.length > 0 ? arrFilteredRole[0] : {};

  if (filteredRole.name && filteredRole.id) {
    return {
      label: filteredRole.name,
      key: filteredRole.id,
      value: filteredRole.id,
    };
  }

  return undefined;
}

function removeURLParamGoBack(location = '', urlPop = 1) {
  const prev = location.split('/');
  for (var a = 0; a < urlPop; a++) {
    prev.pop();
  }
  const loc = prev.join('/');

  return loc;
}

function getLocationSelectValue(locations = [], id) {
  const arrFilteredLoc = locations.filter(loc => loc.id === id);
  const filteredLoc = arrFilteredLoc.length > 0 ? arrFilteredLoc[0] : {};

  if (filteredLoc.code && filteredLoc.id) {
    return {
      label: filteredLoc.code,
      value: filteredLoc.id,
    };
  }

  return null;
}

function getMultipleLocationSelectValue(locations = []) {
  const arrFilteredLoc = locations.map(loc => {
    return {
      ...loc,
      label: loc.code ? loc.code : '',
      value: loc.id ? loc.id : '',
      key: loc.id ? loc.id : '',
    };
  });
  const filteredLoc =
    arrFilteredLoc && arrFilteredLoc.length > 0 ? arrFilteredLoc : null;

  return filteredLoc;
}

function getCustomersSelectValue(customers = []) {
  const arrFilteredCustomer = customers.map(customer => {
    return {
      ...customer,
      label: customer.name ? customer.name : '',
      key: customer.id ? customer.id : '',
    };
  });
  const filteredCustomer =
    arrFilteredCustomer && arrFilteredCustomer.length > 0
      ? arrFilteredCustomer
      : [];

  return filteredCustomer;
}
function getDefaultLocationSelectValue(item = {}) {
  const arrFilteredItem = {
    ...item,
    label: item.code || '',
    key: item.id || '',
  };
  const filteredItem =
    arrFilteredItem && Object.keys(arrFilteredItem).length > 0
      ? arrFilteredItem
      : [];

  return filteredItem;
}
function getTruckerSelectValue(item = {}) {
  const arrFilteredItem = {
    ...item,
    label: item.name || '',
    key: item.id || '',
  };
  const filteredItem =
    arrFilteredItem && Object.keys(arrFilteredItem).length > 0
      ? arrFilteredItem
      : [];
  return filteredItem;
}
function getReasonCodeSelectValue(item = {}) {
  const arrFilteredItem = {
    ...item,
    label: item.code || '',
    key: item.id || '',
  };
  const filteredItem =
    arrFilteredItem && Object.keys(arrFilteredItem).length > 0
      ? arrFilteredItem
      : [];
  return filteredItem;
}
function getTruckerIdSelectValue(item = {}) {
  const arrFilteredItem = {
    ...item,
    label: item.trucker_id || '',
    key: item.id || '',
  };
  const filteredItem =
    arrFilteredItem && Object.keys(arrFilteredItem).length > 0
      ? arrFilteredItem
      : [];
  return filteredItem;
}

function getTruckerIdNameSelectValue(item = {}) {
  const arrFilteredItem = {
    ...item,
    label:
      item.trucker_id && item.name ? `${item.trucker_id} - ${item.name}` : '',
    key: item.id || '',
  };
  const filteredItem =
    arrFilteredItem && Object.keys(arrFilteredItem).length > 0
      ? arrFilteredItem
      : [];
  return filteredItem;
}

function getVehicleTypeSelectValue(item = {}) {
  const arrFilteredItem = {
    ...item,
    label: item.type || '',
    key: item.id || '',
  };
  const filteredItem =
    arrFilteredItem && Object.keys(arrFilteredItem).length > 0
      ? arrFilteredItem
      : [];
  return filteredItem;
}

function extractBreadcrumb(pathname = '') {
  var paths = pathname.split('/');

  // remove the last element if there was a / at the end of the pathname
  paths =
    paths[paths.length - 1] === '' ? paths.slice(0, paths.length - 1) : paths;

  paths = paths[0] === '' ? paths.slice(1) : paths;
  // remove the first element if the second one is an empty string which means that we are in the root of the website
  paths = paths[1] === '' ? paths.slice(1) : paths;
  return paths;
}

function insertToArray(arr, index, ...newItems) {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
}

function serializeUrl(obj, prefix) {
  function start() {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        if (v !== '' && typeof v !== 'undefined')
          str.push(
            v !== null && typeof v === 'object'
              ? serializeUrl(v, k)
              : encodeURIComponent(k) + '=' + encodeURIComponent(v),
          );
      }
    }
    return str.join('&');
  }

  const start_value = start();
  return '&' + start_value;
}

function titleCase(input) {
  if (input == null) return '';
  else
    return input
      .replace(/-/g, ' ')
      .replace(/_/g, ' ')
      .replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
}
function isEquivalent(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (
      JSON.stringify(
        typeof a[propName] === 'number'
          ? a[propName].toString()
          : a[propName] || '',
      ) !==
      JSON.stringify(
        typeof b[propName] === 'number'
          ? b[propName].toString()
          : b[propName] || '',
      )
    ) {
      return false;
    }
  }
  return true;
}
function getUniqueFromArray(a = [], b = []) {
  let valuesA = a.reduce(
    (a, { value }) => Object.assign(a, { [value]: value }),
    {},
  );
  let valuesB = b.reduce(
    (a, { value }) => Object.assign(a, { [value]: value }),
    {},
  );
  let result = [
    ...a.filter(({ value }) => !valuesB[value]),
    ...b.filter(({ value }) => !valuesA[value]),
  ];
  return result;
}

function equalObject(a, b) {
  // eslint-disable-next-line no-unused-vars
  for (let i in a) {
    if (typeof b[i] === 'undefined') {
      return false;
    }
    if (typeof b[i] === 'object') {
      if (!b[i].equals(a[i])) {
        return false;
      }
    }
    if (b[i] !== a[i]) {
      return false;
    }
  }
  // eslint-disable-next-line no-unused-vars
  for (let i in b) {
    if (typeof a[i] === 'undefined') {
      return false;
    }
    if (typeof a[i] === 'object') {
      if (!a[i].equals(b[i])) {
        return false;
      }
    }
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

function moduleURL(input = '') {
  return input.replace(/ /g, '-').toLowerCase();
}

function getDescendantProp(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

const gDp = function(obj, path, def) {
  let val = getDescendantProp(obj, path);
  let typeval = typeOf(val);
  let typeCondVal =
    typeval === 'boolean' && val === false
      ? false
      : typeval === 'number' && val === 0
      ? 0
      : def;
  return val || def || typeCondVal;
};

function currency(
  input,
  {
    returnZero = false,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    symbol = '₱',
    noSymbol = false,
  } = {},
) {
  return input || returnZero
    ? (noSymbol ? '' : symbol) +
        '' +
        parseFloat(input || '0').toLocaleString(
          {},
          {
            minimumFractionDigits: minimumFractionDigits,
            maximumFractionDigits: maximumFractionDigits,
          },
        )
    : handleDisplay('');
}

function decimal(
  input,
  {
    returnZero = false,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  } = {},
) {
  return currency(input, {
    returnZero,
    minimumFractionDigits,
    maximumFractionDigits,
    noSymbol: true,
  });
}

function handleDisplay(input, str_msg = '--', pre, sup) {
  return input || input === 0 ? (
    (pre ? pre : '') + input + (sup ? sup : '')
  ) : (
    <span className="text-uppercase text-italic low-opacity">{str_msg}</span>
  );
}

function handleLink(input, { to = '', str_msg, pre, sup }) {
  return (
    <div>
      {input ? (
        <NavLink className="pad-0" to={to}>
          {input}
        </NavLink>
      ) : (
        handleDisplay(input, str_msg, pre, sup)
      )}
    </div>
  );
}

function handleDate(input, format, emptyString = false) {
  return input && input !== '0000-00-00' ? (
    <span>{moment(input).format(format)}</span>
  ) : emptyString ? (
    ''
  ) : (
    handleDisplay('')
  );
}

function handleDateValue(input, format) {
  return input && input !== '0000-00-00' ? moment(input).format(format) : '';
}

function handleBoolean(input, trueString, falseString) {
  return input ? <span>{trueString}</span> : <span>{falseString}</span>;
}

function handlePage(that, pageState) {
  (document.getElementsByClassName('rt-tbody')[0] || {}).scrollTop = 0;
  // eslint-disable-next-line no-unused-vars
  for (let p in pageState)
    pageState[p] =
      typeOf(pageState[p]) === 'string' && pageState[p] === ''
        ? undefined
        : pageState[p];

  pageState.page = pageState.page + 1;
  pageState.sort_desc = !!pageState.sort_desc || undefined;
  that.state.goBack && that.handleGoBackCount();

  let queryParams = !isEmpty(pageState)
    ? '?' + qs.stringify(pageState, { sort: false })
    : '';
  that.props.history.push(that.props.history.location.pathname + queryParams);
}

function getInitials(name = 'Logistikus ICL') {
  return name
    .split(' ')
    .map(x => x.charAt(0))
    .join('')
    .substr(0, 1)
    .toUpperCase();
}

function reactTableDefault({
  that,
  st,
  className = '',
  searchLoading,
  usePageLimit,
  useMinRows = false,
  data,
}) {
  return {
    manual: true,
    loading:
      typeof searchLoading === 'boolean' ? searchLoading : st.searchLoading,
    data: data || st.data,
    pages: st.totalPage,
    noDataText: st.noDataText,
    columns: st.columns,
    ...(usePageLimit ? { pageSize: st.pageState.limit || 5 } : {}),
    ...(useMinRows ? { minRows: 0 } : {}),
    defaultSorted: gDp(st, 'pageState.sort_id')
      ? [
          {
            id: st.pageState.sort_id,
            desc:
              typeof st.pageState.sort_desc !== 'undefined' &&
              (st.pageState.sort_desc || '').toString() === 'true',
          },
        ]
      : [],
    page: parseInt(gDp(st, 'pageState.page')),
    onPageChange: page => {
      that.setState({ toggleCheckBoxAll: false }, () => {
        handlePage(that, {
          page: page,
          limit: st.pageState.limit,
          search: st.pageState.search || undefined,
          status: st.searchAddDrp.selected || undefined,
          sort_id: st.pageState.sort_id || undefined,
          sort_desc: !!st.pageState.sort_desc || undefined,
          ...st.pageState.filter,
        });
      });
    },
    onPageSizeChange: limit => {
      handlePage(that, {
        page: 0,
        limit: limit,
        search: st.pageState.search || undefined,
        status: st.searchAddDrp.selected || undefined,
        sort_id: st.pageState.sort_id || undefined,
        sort_desc: !!st.pageState.sort_desc || undefined,
        ...st.pageState.filter,
      });
    },
    onSortedChange: sorted => {
      handlePage(that, {
        page: st.pageState.page,
        limit: st.pageState.limit,
        search: st.pageState.search || undefined,
        status: st.searchAddDrp.selected || undefined,
        sort_id: (sorted[0] || {}).id || undefined,
        sort_desc: !!(sorted[0] || {}).desc || undefined,
        ...st.pageState.filter,
      });
      // handlePage({sorted: sorted})
    },
    defaultPageSize: parseInt(gDp(st, 'pageState.limit')) || 10,
    showPaginationBottom: st.hidePagination ? false : true,
    className: `-striped -highlight custom-react ${className}`,
    ...(!useMinRows
      ? {
          style: {
            height: '412px', // This will force the table body to overflow and scroll, since there is not enough room
          },
        }
      : {}),
    pageSizeOptions: [5, 10, 20, 25, 50, 100, 200, 300, 400, 500, 1000],
    // ...(reactTbodyGroup(index) || {})
  };
}

function generateCSVArray(st, datas) {
  let csv_data = datas.map(dData => {
    let colmns = st.columns;
    let return_data = [];
    let manualIndex = 0;
    // eslint-disable-next-line no-unused-vars
    for (let c in colmns) {
      let field_name = colmns[c].accessor || '';
      if (field_name.indexOf('_') === -1) {
        return_data[manualIndex] = getDescendantProp(dData, field_name);
        manualIndex++;
      }
    }
    return return_data;
  });

  let headers = st.columns.reduce((acc, cData) => {
    let field_name = (cData.accessor || '').replace(/\./g, ' ') || '';

    if (field_name.indexOf('_') === -1) return [...acc, field_name];
    return [...acc];
  }, []);

  return [headers, ...csv_data];
}

function exportFile(mod, params, that = { setState: () => {} }) {
  that.setState({ isLoading: true }, () => {
    // API.exportCsv(mod, params).finally(() => {
    //   that.setState({ isLoading: false });
    // });
  });
}

function callBackMessageToastPop() {
  const callBackMessage = (lS.get('callBackMessage') || {}).message;
  if (callBackMessage) {
    TOAST.pop({ message: callBackMessage });
    lS.remove('callBackMessage');
  }
}

function reduceFieldsToInput(state_field_object = {}) {
  let newInputs = {};
  // eslint-disable-next-line no-unused-vars
  for (let state_input in state_field_object) {
    let state_field = state_field_object[state_input];
    newInputs[state_input] = state_field.reduce((inputs, step) => {
      return {
        ...inputs,
        [step.title]: step.inputs.filter(item => Object.keys(item).length > 0),
      };
    }, {});
  }
  return newInputs;
}

function onCopy(label, value) {
  return function(e) {
    e = e || window.event;
    var key = e.which || e.keyCode; // keyCode detection
    var ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false; // ctrl detection
    if (((key === 67 && ctrl) || e.type === 'click') && value) {
      clipboard.writeText(value);
      TOAST.pop({ message: `${label}: ${value || ''} copied!`, type: 'info' });
    }
  };
}

function objectToArray(obj = {}, undefined_cond) {
  const new_obj = obj;
  const new_array =
    Object.entries(new_obj).filter((item = []) => {
      return !(
        undefined_cond &&
        (item[1] === '' || typeof item[1] === 'undefined')
      );
    }) || [];
  return new_array;
}

function processFilterUrl({ that = {}, st = {} }) {
  return function({ filter = {} }) {
    that.setState(
      {
        pageState: { ...st.pageState, filter },
      },
      () => {
        Helpers.handlePage(that, {
          page: 0,
          limit: st.pageState.limit,
          search: st.query,
          status: st.searchAddDrp.selected,
          sort_id: st.pageState.sort_id,
          sort_desc: !!st.pageState.sort_desc,
          ...filter,
        });
      },
    );
  };
}

function plural(input = '') {
  const vowel = ['a', 'e', 'i', 'o', 'u'];
  if (input.slice(-1) === 'y') {
    if (vowel.includes(input.charAt(input.length - 2))) {
      // If the y has a vowel before it (i.e. toys), then you just add the s.
      return input + 's';
    } else {
      // If a input ends in y with a consonant before it (fly), you drop the y and add -ies to make it plural.
      return input.slice(0, -1) + 'ies';
    }
  } else if (input.substring(input.length - 2) === 'us') {
    // ends in us -> i, needs to preceed the generic 's' rule
    return input.slice(0, -2) + 'i';
  } else if (
    ['ch', 'sh'].indexOf(input.substring(input.length - 2)) !== -1 ||
    ['x', 's'].indexOf(input.slice(-1)) !== -1
  ) {
    // If a input ends in ch, sh, x, s, you add -es to make it plural.
    return input + 'es';
  } else {
    // anything else, just add s
    return input + 's';
  }
}

function inputAsync(
  that,
  {
    limit = 9999999,
    field = '',
    name = '',
    label = '',
    sort_desc = 'asc',
    sort_id = 'name',
    isUnique = false,
    customOptionComponents = () => {},
    selectedValue = '',
    routeName = '',
    returnField = '',
    returnDisplay = '',
    field_data_name = '',
    handleInputChange = '',
    add_params = {},
    default_search = '',
    field_id = '',
    isNeededID = false,
  },
) {
  const st = that.state;
  let handleInputFunc = () => {};
  if (typeof handleInputChange === 'function') {
    handleInputFunc = handleInputChange;
  } else {
    handleInputFunc = () => (selectedOption = {}) => {
      that.setState({
        pageState: {
          ...st.pageState,
          filter: {
            ...st.pageState.filter,
            [field]: selectedOption[returnField],
          },
        },
      });
    };
  }

  const new_default_search = default_search || '';

  return {
    input: [
      {
        name,
        label,
        selectedValue,
        customOptionComponents,
        isUnique,
        className: 'white-background',
        type: 'select-dropdown',
        async: true,
        handleInputChange: handleInputFunc,
        loadOptions: () => (inputValue = '', callback) => {
          const st = that.state;
          if (st.typingTimeout) clearTimeout(st.typingTimeout);
          that.setState({
            async_field: { ...(st.async_field || {}), [field]: inputValue },
            typingTimeout: setTimeout(() => {
              const params = {
                page: 1,
                limit,
                search:
                  (inputValue.length <= new_default_search.length
                    ? new_default_search
                    : inputValue) || gDp(st, 'async_field', {})[field],
                status: 'active',
                sort_id,
                sort_desc,
                ...add_params,
              };

              API[routeName]({ params })
                .then(
                  response => {
                    const { data = [] } = response || {};
                    const field_data_check = isNeededID
                      ? data.filter(site => site.code === selectedValue)
                      : '';
                    const field_idNeeded = field_data_check.length
                      ? field_data_check[0].id
                      : '';
                    isNeededID && that.setState({ [field_id]: field_idNeeded });
                    let data_process = data.map(item => ({
                      ...item,
                      label: item[returnDisplay || returnField],
                      value: item[returnDisplay || returnField],
                    }));
                    if (isUnique) data_process = removeDuplicates(data_process);
                    that.setState(
                      {
                        ...(field_data_name
                          ? { [field_data_name]: data_process }
                          : {}),
                      },
                      () => {
                        if (
                          beginsWith(inputValue, new_default_search, true) ||
                          typeof default_search === 'undefined'
                        ) {
                          callback(data_process);
                        } else callback([]);
                      },
                    );
                  },
                  () => {
                    that.setState({ [field_data_name]: [] });
                  },
                )
                .finally(() => {
                  // that.setState({ searchLoading: false })
                });
            }, 500),
          });
        },
      },
    ],
  };
}

function getDataIterate(field_name) {
  return function(obj, stack) {
    var newObj = obj;
    var returnValue;
    for (var property in newObj) {
      if (newObj.hasOwnProperty(property)) {
        if (property === field_name) {
          returnValue = newObj[property];
        } else if (typeof newObj[property] === 'object') {
          getDataIterate(field_name)(newObj[property], stack + '.' + property);
        } else {
        }
      }
    }
    return returnValue;
  };
}

function setDataIterate(field_name, field_value) {
  return function(obj, stack) {
    let newObj = obj;
    for (var property in newObj) {
      if (newObj.hasOwnProperty(property)) {
        newObj[property] =
          property === field_name ? field_value : newObj[property];
        if (typeof newObj[property] === 'object') {
          setDataIterate(field_name, field_value)(
            newObj[property],
            stack + '.' + property,
          );
        } else {
        }
      }
    }
    return newObj;
  };
}

function jsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
}

function parseBool(b) {
  return !/^(false|0)$/i.test(b) && !!b;
}

function controlMaxLength(e, inputMaxLength = 7) {
  e.target.value = Math.max(0, parseFloat(e.target.value))
    .toString()
    .slice(0, inputMaxLength);
}

function integerOnly(e) {
  e.target.value = parseInt(e.target.value) || 0 || '';
}

function getDropdowns(
  data,
  bool,
  { limit = 9999999, search = '', loadOptionsCallBack = () => {} } = {},
) {
  return function(that) {
    const params = !data.id
      ? {
          page: 1,
          limit: limit,
          search: search || '',
          status: 'active',
          ...(data || {}).params,
        }
      : {
          id: data.id,
          page: 1,
          limit: limit,
          status: 'active',
          ...(data || {}).params,
        };

    return API[data.method]({ params }).then(
      response => {
        if (data.field_data_name)
          that.setState({ [data.field_data_name]: response.data });

        // eslint-disable-next-line no-unused-vars
        for (let dp in data.populate) {
          const state_fields = that.state[data.populate[dp].state];
          const new_data = response.data.map(item => {
            return {
              ...(data.fields || []).reduce(
                (acc, field) => ({
                  ...acc,
                  [field.varField]: item[field.valField],
                }),
                {},
              ),
              label: item[data.returnField || 'name'],
              value: data.returnLabel
                ? item[data.returnField || 'name']
                : item.id,
              id: item.id,
            };
          });

          const new_inputs = state_fields[0].inputs.map(item => {
            if (item.name === data.populate[dp].identifier) {
              const new_item = {
                ...item,
                options: new_data,
                selectedValue: bool ? '' : item.selectedValue, // test
              };
              return new_item;
            } else return item;
          });
          that.setState(
            {
              [data.populate[dp].state]: [
                {
                  ...state_fields[0],
                  inputs: new_inputs,
                },
              ],
            },
            () => {
              loadOptionsCallBack(new_data);
            },
          );
        }

        if (typeof data.responseSuccess === 'function')
          data.responseSuccess(response);
      },
      err => {
        if (data.method !== 'getBookingReasons') {
          const { message } = err;
          const msg = message + '. ' || '';
          if (msg !== that.toastId) {
            TOAST.pop({ message: msg, type: 'error', toastId: msg });
          }
          that.setState({ isLoading: false });
        }
      },
    );
  };
}

function removeDuplicates(myArr, prop = 'value', last_index) {
  return myArr.filter((obj, pos, arr) => {
    const mapArray = arr.map(mapObj => mapObj[prop]);
    if (!last_index) return mapArray.indexOf(obj[prop]) === pos;
    else return mapArray.lastIndexOf(obj[prop]) === pos;
  });
}

function contains(searchFor, str, vice_versa) {
  const new_searchFor = (searchFor || '').replace(/\\/g, '');
  const new_str = (str || '').replace(/\\/g, '');
  var pattern = new RegExp(new_searchFor, 'gi');
  var revPattern = new RegExp(new_str, 'gi');
  return (
    pattern.test(new_str) || (vice_versa && revPattern.test(new_searchFor))
  );
}

function beginsWith(searchFor, str, vice_versa) {
  const new_searchFor = (searchFor || '').replace(/\\/g, '');
  const new_str = (str || '').replace(/\\/g, '');
  var pattern = new RegExp('^' + new_searchFor, 'gi');
  var revPattern = new RegExp('^' + new_str, 'gi');
  return (
    pattern.test(new_str) || (vice_versa && revPattern.test(new_searchFor))
  );
}

function endsWith(searchFor, str, vice_versa) {
  const new_searchFor = (searchFor || '').replace(/\\/g, '');
  const new_str = (str || '').replace(/\\/g, '');
  var pattern = new RegExp(new_searchFor + '$', 'gi');
  var revPattern = new RegExp(new_str + '$', 'gi');
  return (
    pattern.test(new_str) || (vice_versa && revPattern.test(new_searchFor))
  );
}

function similar(input = '') {
  const newInput = typeof input !== 'string' ? '' : input;
  return newInput
    .toUpperCase()
    .trim()
    .replace(/ñ/g, 'n')
    .replace(/Ñ/g, 'N');
}

const Helpers = {
  serializeUrl: serializeUrl,
  titleCase: titleCase,
  equalObject: equalObject,
  getDescendantProp: getDescendantProp,
  currency: currency,
  decimal: decimal,
  handleDisplay: handleDisplay,
  handleDate: handleDate,
  handleDateValue: handleDateValue,
  handleBoolean: handleBoolean,
  reactTableDefault: reactTableDefault,
  isEmpty: isEmpty,
  moduleURL: moduleURL,
  handlePage: handlePage,
  generateCSVArray: generateCSVArray,
  exportFile: exportFile,
  handleLink: handleLink,
  callBackMessageToastPop: callBackMessageToastPop,
  reduceFieldsToInput: reduceFieldsToInput,
  onCopy: onCopy,
  objectToArray: objectToArray,
  processFilterUrl: processFilterUrl,
  plural: plural,
  handlePhoneDisplay: handlePhoneDisplay,
  handlePhoneFormat: handlePhoneFormat,
  inputAsync: inputAsync,
  getDataIterate: getDataIterate,
  setDataIterate: setDataIterate,
  jsonParse: jsonParse,
  parseBool: parseBool,
  controlMaxLength: controlMaxLength,
  integerOnly: integerOnly,
  getDropdowns: getDropdowns,
  removeDuplicates: removeDuplicates,
  contains: contains,
  beginsWith: beginsWith,
  endsWith: endsWith,
  similar: similar,
};

function handlePhoneDisplay(phone, type) {
  if (type === 'mobile') {
    return phone === '63' || phone === '' ? '' : '0' + phone.substr(-10);
  } else {
    return phone.substr(-10);
  }
}

function handlePhoneFormat(phone, type) {
  const trimmed = phone.replace(/[^0-9]/g, '');
  return handlePhoneDisplay(trimmed, type);
}

function getLocalStorage(key, exact) {
  const val = exact
    ? JSON.parse(localStorage.getItem(key))
    : JSON.parse(localStorage.getItem(key) || '{}') || {};
  return val;
}

function setLocalStorage(key, value, exact) {
  const val = exact ? value : JSON.stringify(value);
  localStorage.setItem(key, val);
}

function removeLocalStorage(key) {
  localStorage.removeItem(key);
}
const lS = {
  get: getLocalStorage,
  set: setLocalStorage,
  remove: removeLocalStorage,
};

const GenerateRandomString = length => {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

function toastState(message, type = 'success') {
  return {
    type: type,
    message: message,
    show: true,
  };
}

function toastPop({
  message,
  context,
  type = 'success',
  autoClose = 3000,
  toastId = '',
}) {
  const Msg = () => (
    <div>
      <h6>{message}</h6>
      <div>{context}</div>
    </div>
  );
  toast(context ? <Msg /> : message, { type, autoClose, toastId });
}

function filterField(target) {
  if (target) {
    target.addEventListener('keydown', function(e) {
      if ([69, 187, 189, 109].includes(e.keyCode)) {
        e.preventDefault();
      }
    });
    target.addEventListener('keyup', function(e) {
      if (e.target.value.length > 10)
        e.target.value = e.target.value.slice(0, 10);
    });
  }
}

export const Field = {
  filterField: filterField,
};

export const MODULE_DEFAULT = [];

export const OPTIONS = {
  day: [
    { name: 'Sunday', value: '0' },
    { name: 'Monday', value: '1' },
    { name: 'Tuesday', value: '2' },
    { name: 'Wednesday', value: '3' },
    { name: 'Thursday', value: '4' },
    { name: 'Friday', value: '5' },
    { name: 'Saturday', value: '6' },
  ],
  time: [
    { id: '', name: 'Select...' },
    { id: '00:00', name: '00:00' },
    { id: '00:30', name: '00:30' },
    { id: '01:00', name: '01:00' },
    { id: '01:30', name: '01:30' },
    { id: '02:00', name: '02:00' },
    { id: '02:30', name: '02:30' },
    { id: '03:00', name: '03:00' },
    { id: '03:30', name: '03:30' },
    { id: '04:00', name: '04:00' },
    { id: '04:30', name: '04:30' },
    { id: '05:00', name: '05:00' },
    { id: '05:30', name: '05:30' },
    { id: '06:00', name: '06:00' },
    { id: '06:30', name: '06:30' },
    { id: '07:00', name: '07:00' },
    { id: '07:30', name: '07:30' },
    { id: '08:00', name: '08:00' },
    { id: '08:30', name: '08:30' },
    { id: '09:00', name: '09:00' },
    { id: '09:30', name: '09:30' },
    { id: '10:00', name: '10:00' },
    { id: '10:30', name: '10:30' },
    { id: '11:00', name: '11:00' },
    { id: '11:30', name: '11:30' },
    { id: '12:00', name: '12:00' },
    { id: '12:30', name: '12:30' },
    { id: '13:00', name: '13:00' },
    { id: '13:30', name: '13:30' },
    { id: '14:00', name: '14:00' },
    { id: '14:30', name: '14:30' },
    { id: '15:00', name: '15:00' },
    { id: '15:30', name: '15:30' },
    { id: '16:00', name: '16:00' },
    { id: '16:30', name: '16:30' },
    { id: '17:00', name: '17:00' },
    { id: '17:30', name: '17:30' },
    { id: '18:00', name: '18:00' },
    { id: '18:30', name: '18:30' },
    { id: '19:00', name: '19:00' },
    { id: '19:30', name: '19:30' },
    { id: '20:00', name: '20:00' },
    { id: '20:30', name: '20:30' },
    { id: '21:00', name: '21:00' },
    { id: '21:30', name: '21:30' },
    { id: '22:00', name: '22:00' },
    { id: '22:30', name: '22:30' },
    { id: '22:00', name: '22:00' },
    { id: '23:00', name: '23:00' },
    { id: '23:30', name: '23:30' },
  ],
  leg_behavior: [
    {
      label: 'PUD',
      value: 'PUD',
    },
  ],
  appointment_types: [
    {
      label: 'Planned',
      value: 'PLANNED',
    },
    {
      label: 'Ad Hoc',
      value: 'ADHOC',
    },
  ],
  appointment_status: [
    {
      label: 'Draft',
      value: 'draft',
    },
    {
      label: 'Planned',
      value: 'planned',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
    {
      label: 'Accepted',
      value: 'accepted',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
    {
      label: 'Assigned',
      value: 'assigned',
    },
    {
      label: 'No Show',
      value: 'no show',
    },
    {
      label: 'Dispatched',
      value: 'dispatched',
    },
  ],
  trucker_response: [
    {
      label: 'Accepted',
      value: 'ACCEPTED',
    },
    {
      label: 'Rejected',
      value: 'REJECTED',
    },
  ],
  update_appointment_status: {
    draft: ['Planned', 'Cancelled'],
    planned: ['Accepted', 'Rejected', 'Cancelled'],
  },
  leg_detail: {
    leg_behavior: ['Pickup', 'Delivery'],
    status: ['Partial', 'Pending', 'Complete', 'Failed'],
  },
};

export const REG_EX_EMAIL =
  '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/';
export const REG_EX_NO_SPCL_ORIG = '^[-0-9a-zA-Z\b]+$'; // "^[A-Za-z0-9]+$"
export const REG_EX_NO_SPCLCHAR = '^[-0-9a-zA-ZñÑ \b]+$'; // "^[A-Za-z0-9]+$"
// eslint-disable-next-line quotes
export const REG_EX_NO_SPCLCHAR_ADD = "^[-0-9a-zA-ZñÑ()-.,' \b]+$"; // "^[A-Za-z0-9]+$"
export const REG_EX_NO_SPCLCHAR_SPACE = '^[-0-9a-zA-ZñÑ\b]+$'; // "^[A-Za-z0-9]+$"
export const REG_EX_NO_SC = '/^[^ ]+$/';
export const REG_EX_NO_SPACE = '/^[^ ]+$/';
export const REG_EX_FLOATS = '^[0-9]*.?[0-9]+$';
export const REG_EX_NUM = '^[0-9]*$';
export const REG_EX_CHAR = '^[a-zA-Z]+$';
export const REG_EX_NO_NUM = '^[a-zA-Z \b]+$'; // "^[A-Za-z0-9]+$"
export const REG_EX_NO_NUM_SPACE = '^[a-zA-Z\b]+$'; // "^[A-Za-z0-9]+$"
export const REG_EX_REAL_NUM = '^-?[0-9]d*(.d+)?$';
export const REG_EX_NUM_PATTERN =
  '^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)$';

export const MIN_CHAR = 2;
export const MAX_CHAR = 128;
export const MIN_VAL = 0;

export const MESSAGE_NO_SPCLCHAR =
  'must be composed only with letters and numbers';
export const MESSAGE_NO_SPACE = 'must have no white spaces';
export const MESSAGE_NO_SPCLCHAR_SPACE =
  'must be composed only with letters and numbers without spaces';
export const MESSAGE_FLOATS =
  'must be composed only with positive numbers or valid floats';
export const MESSAGE_EMAIL = 'must be valid';
export const MESSAGE_NUM = 'must be composed only with positive numbers';
export const MESSAGE_MIN_CHAR = `must be between ${MIN_CHAR} and ${MAX_CHAR} characters`;
export const MESSAGE_POS_NUM = 'must only be positive numbers';
export const MESSAGE_CHAR_ONLY = 'must only be characters';
export const MESSAGE_NO_NUM =
  'must be composed only with characters with spaces';
export const MESSAGE_NO_NUM_SPACE =
  'must be composed only with characters without spaces';
export const MESSAGE_REAL_NUM = 'must be composed only with numbers';

export const DATE_FORMAT = 'MMMM DD, YYYY';
export const TIME_FORMAT = 'hh:mm a';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const DATE = {
  DATE_DASH: 'YYYY-MM-DD',
  DATE_FORMAT,
  TIME_FORMAT,
  DATETIME_FORMAT,
};

export const CONTACT_NUMBER = '+63 999-563-1279';
export const FACEBOOK_PAGE = 'https://www.facebook.com/codedisruptors';
export const MAIN_WEBSITE = 'http://codedisruptors.com/#!/main';

export const DEFAULT_STATUS = 'Active';

export const CONTENT_DATE = {
  content: ({ COL_FIELD }) => {
    return (
      <span>
        {' '}
        {COL_FIELD !== '0000-00-00' && COL_FIELD ? (
          <span>{moment(COL_FIELD).format(DATE_FORMAT)}</span>
        ) : (
          Helpers.handleDisplay('', '--')
        )}{' '}
      </span>
    );
  },
};

export const CONTENT_DATETIME = {
  content: ({ COL_FIELD }) => {
    return (
      <span>
        {' '}
        {COL_FIELD !== '0000-00-00 00:00' && COL_FIELD ? (
          <span>{moment(COL_FIELD).format(DATETIME_FORMAT)}</span>
        ) : (
          Helpers.handleDisplay('', '--')
        )}{' '}
      </span>
    );
  },
};

export const CONTENT_PRICE = {
  content: ({ COL_FIELD }) => {
    return (
      <span>
        {' '}
        {COL_FIELD
          ? Helpers.currency(COL_FIELD)
          : Helpers.handleDisplay('', '0')}{' '}
      </span>
    );
  },
};

export const CONTENT_TITLECASE = {
  content: ({ COL_FIELD }) => {
    return (
      <span>
        {' '}
        {COL_FIELD
          ? Helpers.titleCase(COL_FIELD)
          : Helpers.handleDisplay('', '--')}{' '}
      </span>
    );
  },
};

export const CONTENT_LINK = ({ to = '', fields = [] }) => {
  return {
    content: ({ COL_FIELD, original }) => {
      let toFormat = to;
      // eslint-disable-next-line no-unused-vars
      for (let f in fields)
        toFormat =
          toFormat.replace(
            ':' + fields[f],
            getDescendantProp(original, fields[f]),
          ) || '';
      return handleLink(COL_FIELD, { to: toFormat });
    },
  };
};

export const getExcel = ({ response, fileName = '' }) => {
  const FileSaver = require('file-saver');
  const blob = new Blob([response], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  FileSaver.saveAs(blob, `${fileName}.xlsx`);
};

export const getZip = ({ response, fileName = '' }) => {
  const FileSaver = require('file-saver');
  const blob = new Blob([response], {
    type: 'application/zip',
  });
  FileSaver.saveAs(blob, `${fileName}.zip`);
};

// export const getExcelStream = ({ response, fileName = '' }) => {
//   const FileSaver = require('file-saver');
//   const blob = response.data.pipe(streamSaver.createWriteStream(fileName));
//   FileSaver.saveAs(blob, `${fileName}.xlsx`);
// };

export const getPDF = ({ response, fileName = '' }) => {
  const FileSaver = require('file-saver');
  const blob = new Blob([response], {
    type: 'application/pdf;charset=utf-8',
  });
  FileSaver.saveAs(blob, `${fileName}.pdf`);
};

export const DefaultState = {
  titleHeader: '',
  data: [],
  filters: {
    page: 1,
    pageSize: 10,
    sort_desc: 'DESC',
    sort_by: 'created_at',
  },
  modalMode: 'CREATE',
  searchLoading: false,
  pages: null,
  isItemModalOpen: false,
  isConfirmModalOpen: false,
  confirmModalDetails: {
    title: '',
    status: '',
    data: [],
  },
  query: '',
  itemModalTitle: '',
  noDataText: 'No data available.',
  selected: {},
  status: 'Activate',
  toastrVisible: false,
  toastrMessage: '',
  toastrType: 'success',
  toastrPosition: 'TOP_RIGHT',
  toastrCloseTime: 6000,
  toast: {
    show: false,
    message: '',
    type: 'success',
    position: 'TOP_RIGHT',
    closeTime: 6000,
  },
  columns: [],
  fields: [],
  listDetails: {
    title: '',
    subTitle: '',
    haveBorder: null,
    showStatus: null,
    statusName: '',
    data: [],
  },
  tableFormDetails: {
    title: '',
    subTitle: '',
    haveBorder: null,
    showStatus: null,
    statusName: '',
    columns: [],
    data: [],
    items: {},
    loadingData: false,
  },
  searchAddDrp: {
    type: 'dropdown',
    title: '',
    selected: '',
    values: [
      {
        value: '',
        label: 'All',
      },
    ],
  },
  searchAddBtn: {
    type: 'button',
    color: 'primary',
    title: '+ Create new',
  },
  submitted: false,
};

export const TOAST = {
  toastState: toastState,
  pop: toastPop,
};

export const handleQueries = (
  query_string = [],
  filter_state = {},
  isUriEncode = false,
) => {
  const new_queries = query_string
    .map(string => {
      const query = string.trim();
      const clean_query =
        query[0] === '_'
          ? query.substring(1)
          : query === 'pageSize'
          ? 'limit'
          : query;
      let a = filter_state[clean_query];

      if ('boolean' === typeof a) {
        a = a.toString();
      }
      const filter_query = (a || '').trim();
      const encode_uri_query = filter_query
        ? encodeURIComponent(filter_query)
        : '';
      return `${query}=${isUriEncode ? encode_uri_query : filter_query}`;
    })
    .join('&');
  return new_queries;
};

export const handleQueriesV2 = (query_string = [], filter_state = {}) => {
  const new_queries = query_string
    .map(string => {
      const query = string.trim();
      const clean_query = query === 'pageSize' ? 'limit' : query;
      const filter_query = (filter_state[clean_query] || '').trim();
      return `${query}=${filter_query}`;
    })
    .join('&');
  return new_queries;
};

export const handleInputNumber = (inputNumber = 0, decimal = true) => {
  let reconstruct = '';
  if (inputNumber) {
    if (decimal) {
      reconstruct = inputNumber.toString().replace(/[^\d.]/g, '');
    } else {
      reconstruct = inputNumber.toString().replace(/[^\d]/g, '');
    }
  }
  return reconstruct;
};

export const inputValidation = (inputText = '', isBarcode = false) => {
  let reconstruct = '';
  if (inputText) {
    if (isBarcode) {
      reconstruct = inputText.toUpperCase();
    }
    return reconstruct;
  } else {
    return inputText;
  }
};

export const handleKeyDown = (e, tagName = '') => {
  const key = e.key;

  // specify
  const is_alphanumeric = /[A-Za-z0-9 ]/.test(key);
  const is_alpha = /[A-Za-z ]/.test(key);
  const is_barcodeSpecialChar = /[.$/+%-]/.test(key);
  const is_postalSpecialChar = /[-]/.test(key);
  const is_codeSpecialChar = /[_-]/.test(key);
  const is_descriptionSpecialChar = /['_&-]/.test(key);

  //prevent
  const some_chars = /[$^*()+\\|?[]/.test(key); // prevent $^*()+\|?[ special characters

  if (tagName === 'alphanumeric') {
    return is_alphanumeric ? is_alphanumeric : e.preventDefault();
  } else if (tagName === 'alphabet') {
    return is_alpha ? is_alpha : e.preventDefault();
  } else if (tagName === 'barcode') {
    return is_barcodeSpecialChar || is_alphanumeric ? true : e.preventDefault();
  } else if (tagName === 'postal') {
    return is_postalSpecialChar || is_alphanumeric ? true : e.preventDefault();
  } else if (tagName === 'code') {
    return is_codeSpecialChar || is_alphanumeric ? true : e.preventDefault();
  } else if (tagName === 'description') {
    return is_descriptionSpecialChar || is_alphanumeric
      ? true
      : e.preventDefault();
  } else if (tagName === 'somechar') {
    return some_chars && e.preventDefault();
  }
};

export const getIcons = module_name => {
  switch (module_name) {
    case 'dashboard':
      return <DashboardOutlined />;
    case 'mobile monitoring':
      return <MobileOutlined />;
    case 'maps and visualizations':
      return <AimOutlined />;
    case 'trip management':
      return <BookOutlined />;
    case 'vehicle appointment':
      return <CarOutlined />;
    case 'reports':
      return <ContainerOutlined />;
    case 'user management':
      return <UserOutlined />;
    case 'role management':
      return <LockOutlined />;
    case 'transaction logs':
      return <SnippetsOutlined />;
    case 'audit trail':
      return <AuditOutlined />;
    case 'setting':
      return <ToolOutlined />;
    case 'data management':
      return <DatabaseOutlined />;
    case 'messages':
      return <MessageOutlined />;
    case 'vehicle registration':
      return <ContactsOutlined />;
    case 'trucker appointment':
      return <CalendarOutlined />;
    default:
      return <DashboardOutlined />;
  }
};

export const selectStyles = {
  control: (styles, { isSelected, isFocused }) => ({
    ...styles,

    boxShadow: isSelected || isFocused ? 'none' : '',
    border: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
  }),
  input: styles => ({
    ...styles,
    padding: 0,
    margin: 0,
    minHeight: '30px',
    lineHeight: '30px',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => {
    return {
      ...styles,
      color: isDisabled ? '#ccc' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      padding: '0px 10px',
      backgroundColor: isSelected ? '#f2f2f2' : 'white',

      '&:focus': {
        backgroundColor: isSelected
          ? '#f2f2f2'
          : isFocused
          ? '#f2f2f2'
          : '#F27124',
      },
      '&:hover': {
        backgroundColor: '#F2712470',
      },
      '&:active': {
        backgroundColor: '#F2712470',
      },
    };
  },
  menu: styles => ({ ...styles, zIndex: 2 }),
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      borderRadius: '4px',
      backgroundColor: data.status === 'INACTIVE' ? 'gray' : 'var(--secondary)',
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: '#fff',
    padding: '0 6px',
    fontSize: '14px',
    height: '30px',
    lineHeight: '30px',
  }),
  multiValueRemove: (styles, { data }) => {
    return {
      ...styles,
      color: '#fff',
      padding: '0 6px',
      backgroundColor: data.status === 'INACTIVE' ? 'gray' : 'var(--secondary)',
      borderRadius: '4px',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor:
          data.status === 'INACTIVE' ? 'gray' : 'var(--secondary)',
        color: '#fff',
      },
    };
  },
  singleValue: styles => ({ ...styles, font: '14px Arial' }),
  selectContainer: styles => ({
    ...styles,
  }),
};

export const buildQuery = params => {
  let esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => {
      return k === 'pageSize'
        ? esc('limit') + '=' + esc(params[k])
        : esc(k) + '=' + esc(params[k]);
    })
    .join('&');
};

export const handleRemoveItemInObject = (item_object = {}, key = '') => {
  const new_item = Object.keys(item_object).reduce((item, idx) => {
    if (key !== idx) {
      item[idx] = item_object[idx];
    }
    return item;
  }, {});
  return new_item;
};

export const handleRemoveItem = (
  newValue, //object
  allChanges, //object
  oldValues, //object
  arrayDependent,
  //object or undefined -> support for backend keys,
  //ex: {customer:'fk_customers'} //[field name]:[backend keys]
) => {
  const key = Object.keys(newValue);
  if (key && key.length > 0) {
    const new_item = handleRemoveItemInObject(allChanges, key[0]);
    if (oldValues[key] === newValue[key]) {
      return new_item;
    } else if (Array.isArray(newValue[key])) {
      const backend_key =
        arrayDependent && arrayDependent[key] ? arrayDependent[key] : key;
      if (oldValues[backend_key] !== newValue[key]) {
        const old_ids = oldValues[backend_key]
          ? oldValues[backend_key].map(cv => cv.id)
          : [];
        const new_ids = newValue[key].reduce((acc, cv) => {
          if (typeof cv.value === 'string') {
            const string_to_oject =
              typeof jsonParse(cv.value) === 'object'
                ? jsonParse(cv.value)
                : { id: cv.value };
            acc.push(string_to_oject.id);
          } else {
            acc.push(cv);
          }
          return acc;
        }, []);
        const notEqual =
          JSON.stringify(new_ids.sort()) !== JSON.stringify(old_ids.sort());
        return notEqual ? { ...allChanges, [key]: new_ids } : new_item;
      }
    } else if (key && newValue[key] && typeof newValue[key] === 'object') {
      return newValue[key].key === oldValues[key]
        ? new_item
        : { ...allChanges, [key]: newValue[key].key };
    } else if (!newValue[key] && !oldValues[key]) {
      return new_item;
    } else if (
      typeof newValue[key] === 'boolean' &&
      typeof oldValues[key] === 'string'
    ) {
      return (oldValues[key] === 'ACTIVE' && !newValue[key]) ||
        (oldValues[key] === 'INACTIVE' && !newValue[key])
        ? { ...allChanges, ...newValue }
        : new_item;
    } else {
      return { ...allChanges, ...newValue };
    }
  }
};

export const scrollBottom = element => {
  var elemChatBox = document.getElementById(element);
  if (elemChatBox) {
    setTimeout(() => {
      elemChatBox.scrollTop = elemChatBox.scrollHeight;
    }, 200);
  }
};

export {
  isEquivalent,
  getUniqueFromArray,
  gDp,
  Helpers,
  lS,
  GenerateRandomString,
  serializeUrl,
  moduleURL,
  getRoleSelectValue,
  getMultipleLocationSelectValue,
  getLocationSelectValue,
  getCustomersSelectValue,
  getReasonCodeSelectValue,
  extractBreadcrumb,
  insertToArray,
  getTruckerSelectValue,
  getVehicleTypeSelectValue,
  getTruckerIdSelectValue,
  getTruckerIdNameSelectValue,
  getDefaultLocationSelectValue,
  parseQuery,
  ellipse,
  removeURLParamGoBack,
  jsonParse,
  getInitials,
  random_colors,
  sleep,
  form_layout,
};
