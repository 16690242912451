import * as firebase from 'firebase';

function initialize() {
  const firebaseConfig = {
    apiKey: 'AIzaSyAE3N_lkZtsBZenpuxIzH-w368nbLdlwLg',
    authDomain: 'kronos-firebase.firebaseapp.com',
    databaseURL: 'https://kronos-firebase.firebaseio.com',
    projectId: 'kronos-firebase',
    storageBucket: 'kronos-firebase.appspot.com',
    messagingSenderId: '239135246943',
    appId: '1:239135246943:web:3eff126446188a6b3929ea',
    // measurementId: 'G-6F3TZ3CR94',
  };
  firebase.initializeApp(firebaseConfig);
}

export const Firebase = {
  init: initialize,
  db: firebase.firestore,
};
