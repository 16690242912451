import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.css';
import 'normalize.css/normalize.css';
import 'react-toastify/dist/ReactToastify.css';

import App from 'containers/App';
import GlobalStyle from 'styles';
import { AuthProvider } from 'components/AuthContext';
import { FilterProvider } from 'context/filterContext';
import { Firebase } from 'utils/firebase';
import { TAProvider } from 'context/TAContext';
import * as serviceWorker from './serviceWorker';

Firebase.init();
sessionStorage.clear();
ReactDOM.render(
  <Router>
    <GlobalStyle />
    <AuthProvider>
      <FilterProvider>
        <TAProvider>
          <ToastContainer />
          <App />
        </TAProvider>
      </FilterProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
