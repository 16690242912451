import styled from 'styled-components';
import { Layout } from 'antd';

const StyledCentered = styled.section`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`;

const StyledStatic = styled.section`
  background-color: ${props =>
    props.background ? props.background : '#282c34'};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => (props.background ? '#282c34' : '#fff')};

  .logo {
    height: 94.24px;
    margin-top: -20vh;
  }

  .title {
    font-weight: 400;
    font-size: 30px;
    margin: 1vh 0;
  }

  .content {
    font-weight: 400;
    color: #8f8f8f;
  }
`;

const StyledPadded = styled.section`
  padding: 1em;
`;

const StyledAbsolute = styled.section`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1em;
`;

const StyledFlex = styled.section`
  display: flex;
  width: auto;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'safe'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'safe')};
  flex-flow: row wrap;
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
`;

const StyledMainComponent = styled(Layout)`
  min-height: 100vh;

  .ant-layout-header {
    background-color: var(--header-background);
  }

  .main_wrapper {
    margin-left: ${props => (props.isexpanded === 'true' ? '80px' : '200px')};
    transition: margin-left 0.2s;

    .ant-layout-header {
      position: fixed;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 20px 0 0;
      z-index: 9994;
      width: ${props =>
        props.isexpanded === 'true'
          ? 'calc(100% - 80px)'
          : 'calc(100% - 200px)'};
      transition: margin-left 0.2s;
      transition: width 0.2s;
      text-align: right;
    }
  }

  .main_component {
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    margin: 74px 10px 10px 10px;
    padding: 20px;
    background-color: #fff;
  }
`;

const StyledMainHeader = styled(Layout)`
  margin-bottom: 20px;

  &.collapse-main-header {
    background: #fff;
    position: fixed;
    z-index: 2;
    top: 64px;
    left: ${props => (props.isexpanded === 'true' ? '80px' : '200px')};
    width: ${props =>
      props.isexpanded === 'true' ? 'calc(100% - 80px)' : 'calc(100% - 200px)'};
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }

  .submit_button {
    margin-bottom: 0px;
  }
`;

const StyledLogin = styled.div`
  text-align: center;
  background-color: #1a373e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  .has-error .ant-form-explain,
  .has-error .ant-form-split {
    text-align: left;
    color: #f5222d;
  }

  .has-error .ant-input,
  .has-error .ant-input:hover {
    color: #ffffff !important;
    background: transparent !important;
  }

  .ant-input[disabled] {
    background-color: #f5f5f5 !important;
  }

  .prompt-message {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    background: #7ec3d9;
    border-radius: 4px;
    color: #0c5460;
    width: 500px;
    height: 100%;
    div {
      margin: 0;
      padding: 0 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        text-align: center;
        margin: 0.5em 0;
        width: 100%;
      }
      .close {
        cursor: pointer;
        text-align: left;
        width: 20px;
      }
      .desc {
        font-size: 0.9em;
      }
    }
  }

  img {
    height: 94.24px;
  }
  label {
    display: block;
    margin-top: -10px;
    margin-bottom: 30px;
    font-size: small;
    color: #64696d;
  }

  .text-login {
    cursor: default;
    line-height: 15px;
    font-size: 22px;
    letter-spacing: ${props =>
      props.letterSpacing ? props.letterSpacing : '0em'};
  }

  p {
    *:focus,
    *:active,
    *:visited,
    *:hover {
      outline: none !important;
    }
    cursor: pointer;
    font-size: 0.9em;

    &:last-child {
      cursor: default;
      margin-top: 2em;
      font-style: bold;
    }
  }

  .not-allowed {
    color: #aaaaaa;
    cursor: not-allowed !important;
  }

  .hide {
    display: none;
    opacity: 0;
  }
`;

export {
  StyledAbsolute,
  StyledCentered,
  StyledPadded,
  StyledMainHeader,
  StyledMainComponent,
  StyledLogin,
  StyledFlex,
  StyledStatic,
};
