import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthContext } from 'components/AuthContext';
import { gDp, moduleURL } from 'utils/helpers';

const AppRouter = ({ routes }) => {
  const [module, setModule] = useState({});
  const { is_authenticated } = useContext(AuthContext);

  useEffect(() => {
    function getPermission() {
      const permissions = localStorage.getItem('role_permissions')
        ? JSON.parse(localStorage.getItem('role_permissions') || [])
        : [];

      const permission = gDp(
        permissions.filter(module => module.has_retrieve),
        '0',
      );
      const permission_name = permission
        ? '/' + moduleURL(permission.name)
        : '';
      const sub_permission = gDp(
        gDp(permission, 'submodules', []).filter(
          submodule => submodule.has_retrieve,
        ),
        '0',
      );
      const sub_permission_name = sub_permission
        ? '/' + moduleURL(sub_permission.name)
        : '';
      setModule(`${permission_name}${sub_permission_name}`);
    }
    getPermission();
    return () => {
      setModule('');
    };
  }, [is_authenticated]);

  const defaultPage = () => {
    return is_authenticated === true ? (
      <Redirect to={module || '/no-access'} />
    ) : (
      <Redirect to="/auth" />
    );
  };

  return (
    <Switch>
      {routes.map((route, index) =>
        route.component ? (
          <Route
            key={index}
            path={route.path}
            component={route.component}
            exact={route.exact === undefined ? false : route.exact}
          />
        ) : (
          route.render && (
            <Route
              key={index}
              path={route.path}
              render={route.render}
              exact={route.exact === undefined ? false : route.exact}
            />
          )
        ),
      )}
      {<Route component={defaultPage} />}
    </Switch>
  );
};

AppRouter.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default AppRouter;
