import React, { useState } from 'react';
import moment from 'moment';

const TAContext = React.createContext();

const dateTomorrow = moment()
  .add(1, 'day')
  .endOf('day')
  .format('YYYY-MM-DD');

const initialContext = {
  contextTA: {
    dashboard_rdd_from: dateTomorrow,
    dashboard_rdd_to: dateTomorrow,
  },
};

function TAProvider({ children }) {
  const [filterConfig, setContext] = useState(initialContext);

  function setContextTA(body, context = 'contextTA') {
    context === 'reset'
      ? setContext(initialContext)
      : setContext({ ...filterConfig, [context]: { ...body } });
  }

  return (
    <TAContext.Provider
      value={{
        filterConfig,
        setContextTA,
        initialContext,
        dateTomorrow,
      }}
    >
      {children}
    </TAContext.Provider>
  );
}

export { TAProvider, TAContext };
