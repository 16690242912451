import { lazy } from 'react';

const public_routes = [
  // insert routes only accessible after authentication. follow the example below
  {
    name: 'Auth',
    path: '/auth',
    exact: true,
    component: lazy(() => import('containers/auth/Auth')),
  },
  {
    name: 'Reset',
    path: '/reset',
    exact: true,
    component: lazy(() => import('containers/auth/Reset')),
  },
  // {
  //   name: 'Customer Potal',
  //   path: '/customer-portal',
  //   exact: true,
  //   component: lazy(() => import('containers/customerPortal/PortalLanding')),
  // },
  // {
  //   name: 'Customer Potal',
  //   path: '/customer-portal/booking-request',
  //   exact: true,
  //   component: lazy(() => import('containers/customerPortal/BookingRequest')),
  // },
  // {
  //   name: 'Customer Potal',
  //   path: '/customer-portal/booking-request/:id',
  //   exact: true,
  //   component: lazy(() =>
  //     import('containers/customerPortal/ViewBookingRequest'),
  //   ),
  // },
  {
    name: 'Success',
    path: '/success',
    exact: true,
    component: lazy(() => import('containers/staticPage/Static')),
  },
  {
    name: 'Success',
    path: '/reject',
    exact: true,
    component: lazy(() => import('containers/staticPage/Static')),
  },
  {
    name: 'Success',
    path: '/deactivation',
    exact: true,
    component: lazy(() => import('containers/staticPage/Static')),
  },
  {
    name: 'Success',
    path: '/404',
    exact: true,
    component: lazy(() => import('containers/staticPage/Static')),
  },
];

export default public_routes;
