import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import useDetectOffline from 'use-detect-offline';
import { message } from 'antd';
import { parseQuery } from 'utils/helpers';
import { useLocation } from 'react-router-dom';

import AppRouter from 'components/AppRouter';
import ErrorBoundary from 'components/ErrorBoundary';
import LoadingAnimation from 'components/reusable/LoadingAnimation';
import Maintenance from 'components/Maintenance';
import { AuthContext } from 'components/AuthContext';

import config from 'utils/config';
import public_routes from 'routes/public-routes';
import { Firebase } from 'utils/firebase';
import { PortalProvider } from 'context/portalContext';

const env = process.env.REACT_APP_ENV;
const keyMap = config[env].mapKey;

const Main = lazy(() => import('containers/main/Main'));

const App = () => {
  const { is_authenticated, setAuthValue } = useContext(AuthContext);
  const [on_maintenance, updateMaintenance] = useState(false);

  const { pathname, search } = useLocation();
  const { offline } = useDetectOffline();

  useEffect(() => {
    message[offline ? 'error' : 'success']({
      content: offline ? 'You are offline' : 'You are online',
      duration: 5,
    });
  }, [offline]);

  useEffect(() => {
    localStorage.setItem('error_counter', 0);
  }, []);
  useEffect(() => {
    sessionStorage.clear();
    let obj_url = parseQuery(search);
    let validate_length = Object.keys(obj_url);
    if (
      validate_length.length === 4 &&
      validate_length.includes('first_name') &&
      validate_length.includes('last_name') &&
      validate_length.includes('email') &&
      validate_length.includes('session')
    ) {
      localStorage.clear();
      sessionStorage.clear();
      setAuthValue(false);
    }

    Firebase.db()
      .collection('status')
      .doc('web_maintenance')
      .onSnapshot(function(doc) {
        const maintenance = doc.data() ? doc.data()[env] : false;
        updateMaintenance(maintenance);
      });
  }, [search, setAuthValue]);

  useEffect(() => {
    const s = document.createElement('script');
    s.src = `https://maps.googleapis.com/maps/api/js?key=${keyMap}&libraries=places`;
    s.type = 'text/javascript';
    s.defer = true;
    document.body.appendChild(s);
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingAnimation />}>
        {on_maintenance ? (
          <Maintenance />
        ) : is_authenticated === true && pathname !== '/deactivation' ? (
          <Main />
        ) : (
          <PortalProvider>
            <AppRouter routes={public_routes} />
          </PortalProvider>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
