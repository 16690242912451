export default {
  staging: {
    route: 'https://api-chronos-staging.codedisruptors.com',
    mapKey: 'AIzaSyDuRuBjFyilf0RJLeTczFUp_JiiH8SfjAM',
  },
  test: {
    route: 'https://centaurus.aeolus.ph',
    mapKey: 'AIzaSyCB6ZT7xbceKKq7xySy3ieKJubGFdpsXno',
  },
  production: {
    route: 'https://delphinus.aeolus.ph',
    mapKey: 'AIzaSyCVL2FiZkvtgbdjnwaKD9jVkUqxSFnigLw',
  },
};
