import React, { createContext, useContext, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import ApiService from 'utils/ApiService';
import { FilterContext } from 'context/filterContext';
import { popToast } from 'utils/useToast';

const PortalContext = createContext();
const Provider = PortalContext.Provider;

const { post, get } = ApiService();

const Auth = props => {
  const auth = localStorage.p_auth && JSON.parse(localStorage.p_auth).isLogin;
  const { setContextValue } = useContext(FilterContext);
  const [isLogin, setIsLogin] = useState(auth);
  const [isModal, setIsModal] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [isTrack, setIsTrack] = useState(false);
  const [isCompLoading, setIsCompLoading] = useState(true);

  let { pathname } = useLocation();
  let { push } = useHistory();

  const value = {
    signIn: signIn,
    signOut: signOut,
    setIsLogin: setIsLogin,
    isLogin: isLogin,
    verify: verify,
    isModal: isModal,
    setIsModal: setIsModal,
    loginLoading: loginLoading,
    isTrack: isTrack,
    setIsTrack: setIsTrack,
    resetFilter: resetFilter,
    isPublic: isPublic,
    isCompLoading: isCompLoading,
  };

  function signIn(values) {
    setLoginLoading(true);
    post('/customer-portal/login', values)
      .then(res => {
        const local = JSON.stringify({ ...res.data, isLogin: true });
        localStorage.setItem('p_auth', local);
        setLoginLoading(false);
        setIsLogin(true);
        setIsModal(false);
        setIsTrack(false);

        popToast({
          message: res.message,
          type: 'success',
        });
      })
      .catch(err => {
        const res = { ...err, ...err[0] };
        const {
          data: { error },
        } = res;
        setLoginLoading(false);
        popToast({
          message: error ? error.message : 'Something went wrong',
          type: 'error',
        });
      });
  }

  function verify() {
    localStorage.setItem('c_portal', true);
    publicValidation();
    if (pathname !== '/customer-portal') {
      if (!isLogin) {
        popToast({
          message: 'Access Denied.',
          type: 'error',
        });
        push('/customer-portal');
      }
    } else {
      resetFilter();
    }
  }

  function signOut() {
    setLoginLoading(true);
    post('/customer-portal/logout')
      .then(res => {
        setLoginLoading(false);
        setIsLogin(false);
        setIsModal(false);
        setIsTrack(false);
        localStorage.clear();
        sessionStorage.clear();
        publicValidation();

        popToast({
          message: res.message,
          type: 'success',
        });
        push('/customer-portal');
      })
      .catch(err => {
        const res = { ...err, ...err[0] };
        const {
          data: { error },
        } = res;

        setLoginLoading(false);
        setIsLogin(false);
        setIsTrack(false);
        publicValidation();
        popToast({
          message: error ? error.message : 'Something went wrong',
          type: 'error',
        });
        localStorage.clear();
        sessionStorage.clear();
        push('/customer-portal');
      });
  }

  function publicValidation() {
    setIsCompLoading(true);
    get('/settings/customer-portal')
      .then(res => {
        const value = res.value === 'true';
        setIsPublic(value);
        setIsCompLoading(false);
      })
      .catch(() => setIsCompLoading(false));
  }

  function resetFilter() {
    setContextValue({}, 'reset');
  }
  return <Provider value={value}>{props.children}</Provider>;
};

const PortalProvider = withRouter(Auth);
export { PortalContext, PortalProvider };
